import { executeApi, useApi } from '../../composables/useApi'
import { ref, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'

export default createSharedComposable(() => {
  const api = useApi()

  const selectedRoadmapMemberId = ref(null)
  const selectedRoadmapData = ref({})

  watch(() => selectedRoadmapMemberId.value, (memberId) => {
    fetchRoadmapData(memberId)
  })

  const fetchRoadmapData = (memberId) => {
    executeApi(async () => {
      const { data } = await api.myPond.getRoadmap({
        searchMemberId: memberId
      })
      selectedRoadmapData.value = {}
      setTimeout(() => {
        selectedRoadmapData.value = data
      }, 1)
    },
e => {
      console.log(e)
    })
  }

  return {
    selectedRoadmapMemberId,
    selectedRoadmapData,
    fetchRoadmapData
  }
})
